.password-verify-weak {
    background-color: #ff9800;
    color: black;
  }
  
  .password-verify-medium {
    background-color: #ffeb3b;
    color: black;
  }
  
  .password-verify-strong {
    background-color: #8bc34a;
    color: black;
  }
  
  .password-verify-very-strong {
    background-color: #4caf50;
    color: black;
  }
  
  .chart-select-date {
    border: none;
    width: 100px;
    cursor: pointer;
  }
  
  
  ///////////////////
  .widget-select-range-interval-header {
    font-size: 0.7rem;
  }
  
  .widget-select-range-interval-item {
    font-size: 0.9rem;
  }
  
  ///////////////////
  .vis-event .vis-item-content {
    background-color: lightgray;
  }
  
  .vis-event-post .vis-item-content {
    background-color: lightgreen;
  }
  
  .vis-event-put .vis-item-content {
    background-color: lightblue;
  }
  
  .vis-event-delete .vis-item-content {
    background-color: lightpink;
  }
  
  
  .vis-range  {
    background-color: #adb5bd !important;
    border-color: #adb5bd !important;
    
  }
  
  .vis-selected {
    background-color: #343d52 !important;
    border-color: #343d52 !important;;
    color: white !important;
  }
  
  
  
  [data-bs-theme="dark"] {
    .edxr-vis-group,
    .vis-even,
    .vis-odd {
      color: white !important
    }
  }
  
  
  
  ///////////////////
  
  .ag-react-container {
    width: 100%;
  }
  
  ///////////////////
  
  .file-select {
    border: dashed;
    border-radius: 10px;
    border-color: darkgray;
    padding: 10px;
  }
  
  .file-select-drag:hover {
    border-color: green !important;
  }
  