@import './bootstrap-edxr.scss';

@import './layout.scss';
@import './size.scss';
@import './other.scss';
@import './progress-wizard.scss';

@import '~js-datepicker/dist/datepicker.min.css';

.course-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edxr-pointer {
  cursor: pointer;
}

.logo {
  height: 28px;
  width: auto;
}

.logo-sm {
  height: 20px;
  width: auto;
}

.logo-gray {
  // filter: invert(1) grayscale(1) contrast(5);
  filter: brightness(0) invert(1);
}
